import React from "react";

const storyblokComponentSettings = (props) => (
  <article className="container p-5">
    <div className="row">
      <div className="col text-center">
        <h1>Settings</h1>
        <p>Sorry, there is no preview for the settings page</p>
      </div>
    </div>
  </article>
);

export default storyblokComponentSettings;
