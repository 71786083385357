import storyblokComponentSettings from "./storyblokComponentSettings";
//import storyblokComponentDefault from "./storyblokComponentDefault";
import storyblokComponentDefault from "../../templates/page";
import storyblokComponentDocument from "../../templates/document";
import storyblokComponentNotFound from "./storyblokComponentNotFound";

const ComponentList = {
  settings: storyblokComponentSettings,
  default: storyblokComponentDefault,
  document: storyblokComponentDocument,
};

const Components = (type) => {
  if (typeof ComponentList[type] === "undefined") {
    return storyblokComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
