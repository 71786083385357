import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SEO from "../utils/SEO";
import { gsap } from "gsap";

// ====

const Page = (props) => {
  const { pageContext, blok } = props;
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  useEffect(() => {
    gsap.utils.toArray(".brand-animated").forEach((element) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: element,
          toggleClass: "brand-animated-active",
          start: "top 75%",
          once: true,
        },
      });
    });
  }, []);

  return (
    <>
      <SEO openGraph={openGraph} meta={doc.meta} />
      <article>A page</article>
    </>
  );
};

export default Page;

Page.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blok: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Page.defaultProps = {
  pageContext: null,
  blok: null,
};
