import React from "react";
import PropTypes from "prop-types";
import SEO from "../utils/SEO";
import SmartText from "../utils/TextHandler";
import ImageHandler from "../utils/ImageHandler";

// ====

const Document = (props) => {
  const { pageContext, blok } = props;
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
    noIndex: true,
  };

  return (
    <>
      <SEO openGraph={openGraph} meta={doc.meta} />
      <article>
        <section className="w-100 d-flex justify-content-center align-items-center position-relative brand-background-gold py-5 overflow-hidden">
          <div className="row justify-content-start py-5">
            <div className="col-12 text-center py-lg-5">
              {doc.title && (
                <h1 className="brand-font brand-text-caps brand-text-headline">
                  {doc.title}
                </h1>
              )}
            </div>
          </div>
        </section>
        <div className="container py-3">
          <section className="brand-background-white">
            {doc.body && <SmartText content={doc.body} />}
          </section>
        </div>
      </article>
    </>
  );
};

export default Document;

Document.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blok: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Document.defaultProps = {
  pageContext: null,
  blok: null,
};
